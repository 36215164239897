import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoFilter } from '@xpo-ltl/ngx-ltl-board';
import { XpoAccountPopoverConfig, XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { take } from 'rxjs/operators';
import { XpoLtlLoggedInUserService } from './arsenal-components/services/logged-in-user.service';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';
import { SymRegions } from './shared/enums/sym-region.enum';
import { GlobalFilterService } from './shared/services/filter/global-filter.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title: string;
  build: string;
  routes: XpoShellRoute[];
  accountPopoverConfig: XpoAccountPopoverConfig;
  filters: XpoFilter[];
  selectedRegion: string;
  isProd: boolean;
  navigationSubscription;

  constructor(
    private configManagerService: ConfigManagerService,
    private authService: XpoLtlAuthenticationService,
    private authConfigService: XpoLtlAuthConfigLoaderService,
    private globalFilterService: GlobalFilterService,
    private loggedInUserService: XpoLtlLoggedInUserService,
    private router: Router
  ) {
    this.routes = [
      {
        label: 'API Components',
        path: `/${AppRoutes.COMPONENTS_PAGE}`,
      },
      {
        label: 'AEM Components',
        path: `/${AppRoutes.AEM_PAGE}`,
      },
    ];

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    this.filters = globalFilterService.setupGlobalFilters();
    /** Shell setup */
    // TODO: Add title to constant
    this.title = 'Arsenal Tool';
    /** Shell setup */
    this.isProd = this.configManagerService.getSetting<boolean>(ConfigManagerProperties.authIsProd);
    this.build = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.selectedRegion = this.isProd ? this.configManagerService.getSetting<string>(ConfigManagerProperties.region) : SymRegions.TCTSI;
  }

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  ngOnInit(): void {
    const region = this.isProd ? this.configManagerService.getSetting<string>(ConfigManagerProperties.region) : SymRegions.TCTSI;
    this.authService.logout();
    this.authService.initAuthSetup$(region).subscribe((info) => {
      // This call triggers filter changed event.
      this.loggedInUserService.initLoggedInUser();
      this.globalFilterService.regionFilter = region;
      this.globalFilterService.publishRegionChange(region);
      if (!this.isProd) {
        this.configManagerService.setSetting('apiUrl', 'https://tctsi-api.ltl.xpo.com');
      }
    });
  }

  onGlobalFilterChange($event): void {
    if (!this.selectedRegion) {
      this.selectedRegion = $event.region;
    } else if ($event.region && $event.region !== this.selectedRegion) {
      this.authService.logout();
      this.authService
        .initAuthSetup$($event.region)
        .pipe(take(1))
        .subscribe((info) => {
          this.loggedInUserService.initLoggedInUser();
          const currentUrl = this.router.url.toString();
          this.authConfigService.apiUrl = info.apiUrl;
          this.configManagerService.setSetting('apiUrl', info.apiUrl);
          this.configManagerService.setSetting('region', $event.region);
          this.globalFilterService.publishRegionChange($event.region);
          currentUrl.includes('api-components') ? this.router.navigateByUrl('/api-components') : this.router.navigateByUrl('/aem');
        });
      this.selectedRegion = $event.region;
    }
  }
}
