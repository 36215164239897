import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoFilter, XpoFiltersService, XpoSelectFilter } from '@xpo-ltl/ngx-ltl-board';
import { BehaviorSubject, Observable } from 'rxjs';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import { Generics } from '../../enums/generic-enum';
import { SymRegions } from '../../enums/sym-region.enum';

@Injectable({ providedIn: 'root' })
export class GlobalFilterService {
  private _region$ = new BehaviorSubject<string>(undefined);
  private _isSideBarOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(public configManagerService: ConfigManagerService, private filterService: XpoFiltersService) {}

  private generateRegionOptions(): any[] {
    return this.symRegions.map((region) => {
      return { title: region, code: region };
    });
  }

  closeSideBar(): void {
    this._isSideBarOpen$.next(false);
  }

  isSideBarOpen$(): Observable<boolean> {
    return this._isSideBarOpen$.asObservable();
  }

  get region$(): Observable<string> {
    return this._region$.asObservable();
  }

  get symRegions(): string[] {
    return Object.keys(SymRegions);
  }

  set regionFilter(value: string) {
    this.filterService.setFieldCriteria(Generics.REGION, value);
  }

  publishRegionChange(region: string): void {
    this._region$.next(region);
  }

  setupGlobalFilters(): XpoFilter[] {
    if (!this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production)) {
      return [
        new XpoSelectFilter({
          field: Generics.REGION,
          label: Generics.GLOBAL_REGION_FILTER,
          isSingleSelect: true,
          options: this.generateRegionOptions(),
        }),
      ];
    }
  }
}
