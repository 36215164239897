import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BreadcrumbEnum } from '../../enums/breadcrumb.enum';
import { Breadcrumb } from '../../models/breadcrumb';
import { StateService } from '../../services/state/state.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'lau-BreadcrumbsContainer',
  },
})
// TODO: This code has been copied from a npm library, try to refactor
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];
  ROUTE_DATA_BREADCRUMB: string = BreadcrumbEnum.BREADCRUMB;
  hasJustOneElement = true;
  serviceName = '';

  constructor(private router: Router, private stateService: StateService) {}

  ngOnInit(): void {
    // subscribe to the NavigationEnd event
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((routeEvent) => {
      this.onRouteEvent();
    });
  }

  changeBreadcrumb(route: ActivatedRouteSnapshot, name: string) {
    const rootUrl = this.createRootUrl(route);
    const breadcrumb = this.breadcrumbs.find((bc) => {
      return bc.url === rootUrl;
    });

    if (!breadcrumb) {
      return;
    }

    breadcrumb.displayName = of(name);
  }

  private onRouteEvent() {
    let route = this.router.routerState.root.snapshot;
    let url = '';

    const newCrumbs = [];

    while (route.firstChild != null) {
      route = route.firstChild;

      if (route.routeConfig === null) {
        continue;
      }
      if (!route.routeConfig.path) {
        continue;
      }

      url += `/${this.createUrl(route)}`;
      newCrumbs.push(this.createBreadcrumb(route, url));
    }

    this.breadcrumbs = newCrumbs;
    this.hasJustOneElement = this.breadcrumbs.length <= 1;
  }

  private createBreadcrumb(route: ActivatedRouteSnapshot, url: string): Breadcrumb {
    return {
      displayName: this.getBreadcrumbLabel(route),
      terminal: this.isTerminal(route),
      url: url,
      route: route.routeConfig,
    };
  }

  private getBreadcrumbLabel(route: ActivatedRouteSnapshot): Observable<string> {
    switch (route.data[this.ROUTE_DATA_BREADCRUMB]) {
      case BreadcrumbEnum.ACTION:
        return this.stateService.getCurrentFeatureNameOrAction();
      case BreadcrumbEnum.FEATURE:
        return this.stateService.getCurrentComponentCd();
      case BreadcrumbEnum.MESSAGE:
        return this.stateService.getCurrentMessageCd();
      case BreadcrumbEnum.CONTRACT:
        return of(route.params[BreadcrumbEnum.SERVICE_NAME]);
      default:
        return of(route.data[this.ROUTE_DATA_BREADCRUMB]);
    }
  }

  private isTerminal(route: ActivatedRouteSnapshot): boolean {
    return route.firstChild === null || route.firstChild.routeConfig === null || !route.firstChild.routeConfig.path;
  }

  private createUrl(route: ActivatedRouteSnapshot) {
    return route.url
      .map(function(s) {
        return s.toString();
      })
      .join('/');
  }

  private createRootUrl(route: ActivatedRouteSnapshot) {
    let url = '';
    let next = route.root;
    // this.serviceName = JSON.stringify(route.root.outlet);

    while (next.firstChild !== null) {
      next = next.firstChild;

      if (next.routeConfig === null) {
        continue;
      }
      if (!next.routeConfig.path) {
        continue;
      }

      url += `/${this.createUrl(next)}`;

      if (next === route) {
        break;
      }
    }

    return url;
  }
}
