import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AppRoutes } from '../../enums/app-routes.enum';
import { FirestoreEnum } from '../../enums/firestore-app.enum';
import { ArsenalConfig, Documentation, FirestoreAppConfig } from '../../models/firestore-app-config';

@Component({
  selector: 'app-helper-menu',
  templateUrl: './helper-menu.component.html',
  styleUrls: ['./helper-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'app-helper-menu',
  },
})
export class HelperMenuComponent implements OnInit {
  links$: Observable<Array<Documentation>>;

  constructor(private fs: AngularFirestore, private router: Router) {}

  private createUrl(route: ActivatedRouteSnapshot) {
    return route.url
      .map(function(s) {
        return s.toString();
      })
      .join('/');
  }

  ngOnInit(): void {
    // subscribe to the NavigationEnd event
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((routeEvent) => {
      this.onRouteEvent();
    });
  }

  private onRouteEvent() {
    let route = this.router.routerState.root.snapshot;

    route = route.firstChild;

    if (route.routeConfig === null) {
      return;
    }
    if (!route.routeConfig.path) {
      return;
    }
    const url = `${this.createUrl(route)}`;

    if (url && url === AppRoutes.AUTH_PAGE) {
      this.links$ = null;
      return;
    }

    this.links$ = this.getSymAppConfigs$(url.includes(AppRoutes.COMPONENTS_PAGE) ? FirestoreEnum.ARSENAL : FirestoreEnum.AEM);
  }

  private getSymAppConfigs$(application: string): Observable<Array<Documentation>> {
    return this.fs
      .collection(FirestoreEnum.APP_KEY_CONFIGS)
      .doc<FirestoreAppConfig>(FirestoreEnum.ARSENAL)
      .get()
      .pipe(
        map((doc) => {
          return doc.data().appConfigs;
        }),
        map((appConfigs: ArsenalConfig) => {
          return appConfigs.documentation.filter((documentation) => documentation.module === application);
        })
      );
  }
}
