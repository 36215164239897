import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ApiMetadataApiService, Component as ComponentSDK, SuperComponent } from '@xpo-ltl/sdk-apimetadata';
import { AuditInfo, User } from '@xpo-ltl/sdk-common';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ArsenalComponentsService } from '../../arsenal-components/services/arsenal-components.service';

@Component({
  selector: 'app-component-detail-dialog',
  templateUrl: './component-detail-dialog.component.html',
  styleUrls: ['./component-detail-dialog.component.scss'],
})
export class ComponentDetailDialogComponent implements OnInit {
  componentForm: FormGroup;
  currentUser: User;
  currentComponentCd: string;
  currentComponent$: Observable<ComponentSDK>;
  editMode: boolean = false;
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ComponentDetailDialogComponent>,
    private arsenalComponentsService: ArsenalComponentsService,
    private apiMetadataApiService: ApiMetadataApiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.componentForm = this.fb.group({
      componentName: ['', Validators.required],
      componentDescription: ['', Validators.required],
      componentCd: ['', [Validators.required, Validators.maxLength(3)]],
      contextUrl: ['', [Validators.required]],
      contractRepoName: ['', [Validators.required]],
      versionNbrTxt: ['', [Validators.required]],
      owningTeam: [''],
      owningTeamManager: [''],
      owningTeamTechnicalLead: [''],
      architectName: [''],
      jiraComponent: ['', [Validators.required]],
      superComponent: ['', [Validators.required, Validators.maxLength(3)]],
    });
    this.currentComponentCd = data ? data.componentCd : null;
  }

  ngOnInit() {
    this.apiMetadataApiService.loggedInUser().subscribe((user: User) => (this.currentUser = user));
    this.initializeForm();
  }

  initializeForm(): void {
    if (this.currentComponentCd) {
      this.editMode = true;
      this.currentComponent$ = this.arsenalComponentsService
        .getComponentDetails(this.currentComponentCd)
        .pipe(tap((component) => this.componentForm.patchValue(component)));
      this.componentForm.controls['superComponent'].disable();
      this.componentForm.controls['superComponent'].setValidators([]);
    } else {
      this.currentComponent$ = of({} as ComponentSDK);
    }
  }

  save(): void {
    const componentValue = {
      ...this.componentForm.value,
      auditInfo: {
        createdById: this.currentUser.userId,
        createdTimestamp: new Date(),
      } as AuditInfo,
      superComponent: { componentCd: this.componentForm.controls['superComponent'].value },
    };
    this.editMode
      ? this.arsenalComponentsService.updateComponent(componentValue).subscribe((resp) => this.dialogRef.close({ action: 'UPDATE' }))
      : this.arsenalComponentsService.addComponent(componentValue).subscribe((resp) => this.dialogRef.close({ action: 'CREATE' }));
  }

  cancel(): void {
    this.dialogRef.close(false);
  }
}
