import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'xpo-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'xpo-info-dialog',
  },
})
export class InfoDialogComponent implements OnInit {
  ngOnInit(): void {}

  // In the constructor define dialogRef
  constructor(public dialogRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: { infoText: string }) {}

  ok(): void {
    // do something and then close
    this.dialogRef.close();
  }

  saveAndReview(): void {
    // do something and then close
    this.dialogRef.close();
  }

  cancel(): void {
    // do something and then close
    this.dialogRef.close();
  }
}
