import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatCheckboxModule, MatIconModule, MatInputModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { XpoBusyLoaderModule, XpoCardModule, XpoDialogModule, XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core';
// tslint:disable-next-line:max-line-length
import { ContractAttributesDialogComponent } from '../arsenal-components/modules/features/components/contract-details/req-resp-list/dialogs/contract-attributes-dialog/contract-attributes-dialog.component';
import { ArsenalConfirmDialogComponent } from './arsenal-confirm-dialog/arsenal-confirm-dialog.component';
import { AttributesListDialogComponent } from './attributes-list-dialog/attributes-list-dialog.component';
import { ComponentDetailDialogComponent } from './component-detail-dialog/component-detail-dialog.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

const dialogs = [
  // YOUR DIALOGS
  NotAuthorizedComponent,
  ArsenalConfirmDialogComponent,
  AttributesListDialogComponent,
  ContractAttributesDialogComponent,
  ComponentDetailDialogComponent,
];

@NgModule({
  declarations: [...dialogs, ComponentDetailDialogComponent],
  imports: [
    // MATERIAL OR NGX CORE COMPONENTS
    XpoCardModule,
    XpoDialogModule,
    MatInputModule,
    MatIconModule,
    FlexLayoutModule,
    MatButtonModule,
    XpoSnackBarModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    BrowserModule,
    XpoBusyLoaderModule,
  ],
  exports: [...dialogs],
  entryComponents: [...dialogs],
})
export class DialogModule {}
