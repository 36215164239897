import { Injectable } from '@angular/core';
import {
  ApiMetadataApiService,
  Contract,
  ContractException,
  ContractType,
  ContractTypeAttribute,
  CreateContractRqst,
} from '@xpo-ltl/sdk-apimetadata';
import { ApiContractStatusCd, ApiContractTypeCategoryCd } from '@xpo-ltl/sdk-common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class ContractsService {
  constructor(private apiMetadataApiService: ApiMetadataApiService) {}

  getContractDetails(componentCd: string, featureId: number, serviceName: string): Observable<Contract> {
    return this.apiMetadataApiService.getContract({ componentCd, featureId, serviceName }).pipe(map((resp) => resp.contract));
  }

  updateContract(contract: Contract, newName: string): Observable<Contract> {
    return this.apiMetadataApiService.updateContract({ contract, newName }).pipe(map((resp) => resp.contract));
  }

  addContract(contract: Contract, oldContractName?: string, oldFeatureId?: number): Observable<Contract> {
    return this.apiMetadataApiService
      .createContract({ contract, oldContractName, oldFeatureId } as CreateContractRqst)
      .pipe(map((resp) => resp.contract));
  }

  deleteContract(componentCd: string, featureId: number, serviceName: string): Observable<void> {
    return this.apiMetadataApiService.deleteContract({ componentCd, featureId, serviceName });
  }

  getContractParamsByType(contract: Contract | any, contractTypeCategory: ApiContractTypeCategoryCd): ContractType {
    const contractType = contract.contractType
      ? contract.contractType.find((type) => type.contractCategoryCd === contractTypeCategory)
      : null;
    return contractType
      ? { ...contractType, contractTypeAttribute: this.sortContractTypeAttributeBySequenceNumber(contractType.contractTypeAttribute) }
      : { contractTypeAttribute: [] };
  }

  getContractExceptions(contract: Contract | any): ContractException[] {
    // In case we  need mapping, TODO: Remove if not necessary
    return contract.contractException;
  }

  /**
   * Get contracts by component and status
   * @param componentCd
   * @param status
   * @returns
   */
  getContractsByComponent(componentCd: string, status: ApiContractStatusCd[] = [ApiContractStatusCd.IMPLEMENTED]): Observable<Contract[]> {
    return this.apiMetadataApiService
      .listContractsByComponentCd({ componentCd }, { statusCd: status, listInfo: null })
      .pipe(map((resp) => resp.contracts));
  }

  sortContractTypeAttributeBySequenceNumber(contractTypeAttributes: ContractTypeAttribute[]): ContractTypeAttribute[] {
    return contractTypeAttributes.sort((a, b) => (Number(a.contractTypeAttrSeqNbr) > Number(b.contractTypeAttrSeqNbr) ? 1 : -1));
  }

  getPreviousContract(componentCd: string, serviceName: string, featureId: number): Observable<Contract> {
    return this.apiMetadataApiService.getContractLastUpdate({ componentCd, serviceName, featureId }).pipe(map((resp) => resp.contract));
  }
}
