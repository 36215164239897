import { ContractType, ContractTypeAttribute, TypeAttribute } from '@xpo-ltl/sdk-apimetadata';

export class JsonViewModel {
  data: any = {};

  constructor(contractType: ContractType) {
    contractType.contractTypeAttribute.forEach((attr, index) => {
      this.addAttribute(this.data, attr);
    });
  }

  private addAttribute(obj: any, attr: ContractTypeAttribute | TypeAttribute): void {
    const isArray: boolean = attr.maximumNbr && +attr.maximumNbr > 1;
    if (attr.parentType && (attr.dataType === 'object' || attr.dataType === attr.parentType.typeName) && attr.parentType.typeAttribute) {
      const name: string = this.getAttrName(attr);

      attr.parentType.typeAttribute.sort((a, b) => (Number(a.attributeSequenceNbr) > Number(b.attributeSequenceNbr) ? 1 : -1));
      attr.parentType.typeAttribute.forEach((pAttr) => {
        if (!obj[name]) {
          obj[name] = isArray ? [{}] : {};
        }

        const currentObj = isArray ? obj[name][0] : obj[name];

        this.addAttribute(currentObj, pAttr);
      });
      return;
    }

    if ('cntrctTypAttrName' in attr) {
      obj[attr.cntrctTypAttrName] = isArray ? [attr.dataType] : attr.enumType ? attr.enumType.enumNameTxt : attr.dataType;
      return;
    }

    if ('attributeName' in attr) {
      if (attr.dataType === 'object' && attr.parentType) {
        if (attr.categoryCd === 'Enum') {
          obj[attr.attributeName] = isArray ? [attr.enumType.enumNameTxt] : attr.enumType.enumNameTxt;
          return;
        } else {
          obj[attr.attributeName] = isArray ? [attr.parentType.typeName] : attr.parentType.typeName;
          return;
        }
      }
      obj[attr.attributeName] = isArray ? [attr.dataType] : attr.dataType;
      return;
    }
  }

  private getAttrName(attr: ContractTypeAttribute | TypeAttribute): string {
    if ('cntrctTypAttrName' in attr) {
      return attr.cntrctTypAttrName;
    }

    if ('attributeName' in attr) {
      return attr.attributeName;
    }
  }
}
