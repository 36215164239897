import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Contract, ContractType } from '@xpo-ltl/sdk-apimetadata';
import { ApiContractTypeCategoryCd } from '@xpo-ltl/sdk-common';
import { JsonViewModel } from '../contract-item/models/json-view.model';

declare var jsondiffpatch;
@Component({
  selector: 'contract-body',
  templateUrl: './contract-body.component.html',
  styleUrls: ['./contract-body.component.scss'],
})
export class ContractBodyComponent implements OnInit, OnChanges {
  @Input() contract: Contract;
  @Input() contractToDiff: Contract;
  @Output() noChangesEmit = new EventEmitter<boolean>();
  response: ContractType;
  request: ContractType;
  hasParameters: boolean;
  requestJson: JsonViewModel;
  responseJson: JsonViewModel;
  noChanges: boolean = true;

  @ViewChild('diffReq', { static: false }) diffReqContainer: ElementRef;
  @ViewChild('diffResp', { static: false }) diffRespContainer: ElementRef;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.setValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.contract && !changes.contract.isFirstChange()) || (changes.contractToDiff && !changes.contractToDiff.isFirstChange())) {
      this.setValues();
    }
  }

  setValues(): void {
    if (this.contract) {
      this.hasParameters = this.contract.contractType.some((c) => {
        return c.contractCategoryCd === ApiContractTypeCategoryCd.PATH || c.contractCategoryCd === ApiContractTypeCategoryCd.QUERY;
      });
      this.response = this.getResponseFromContract(this.contract);
      this.request = this.getRequestFromContract(this.contract);
      this.cd.detectChanges();

      if (this.request) {
        if (this.contractToDiff) {
          const contractToDiffTypeRequest = this.getRequestFromContract(this.contractToDiff);
          const diffFormatted = this.getDiffFromTypes(contractToDiffTypeRequest, this.request);

          if (diffFormatted) {
            this.setDiffInContainer(this.diffReqContainer, diffFormatted);
            this.noChanges = false;
          } else {
            this.setDiffInContainer(this.diffReqContainer, JSON.stringify(new JsonViewModel(this.request).data, null, 2));
          }
        } else {
          this.requestJson = new JsonViewModel(this.request);
          this.requestJson.data = this.sortProperties(this.requestJson.data);
        }
      }

      if (this.response) {
        if (this.contractToDiff) {
          const contractToDiffTypeResponse = this.getResponseFromContract(this.contractToDiff);
          const diffFormatted = this.getDiffFromTypes(contractToDiffTypeResponse, this.response);

          if (diffFormatted) {
            this.setDiffInContainer(this.diffRespContainer, diffFormatted);
            this.noChanges = false;
          } else {
            this.setDiffInContainer(this.diffRespContainer, JSON.stringify(new JsonViewModel(this.response).data, null, 2));
          }
        } else {
          this.responseJson = new JsonViewModel(this.response);
          this.responseJson.data = this.sortProperties(this.responseJson.data);
        }
      }
      this.noChangesEmit.emit(this.noChanges);
    }
  }

  private getResponseFromContract(contract: Contract): ContractType {
    return contract.contractType.find((c) => c.contractCategoryCd === ApiContractTypeCategoryCd.RESPONSE);
  }
  private getRequestFromContract(contract: Contract): ContractType {
    return contract.contractType.find((c) => c.contractCategoryCd === ApiContractTypeCategoryCd.REQUEST);
  }

  private getDiffFromTypes(contractTypeLeft: ContractType, contractTypeRight: ContractType): string {
    let diffFormatted;
    const contractTypeLeftToJson = JSON.stringify(new JsonViewModel(contractTypeLeft), null, 2);
    const contractTypeRightToJson = JSON.stringify(new JsonViewModel(contractTypeRight), null, 2);
    console.log('contractTypeLeftToJson', contractTypeLeftToJson);
    const delta = jsondiffpatch.diff(JSON.parse(contractTypeLeftToJson).data, JSON.parse(contractTypeRightToJson).data);
    if (delta) {
      diffFormatted = jsondiffpatch.formatters.html.format(delta, JSON.parse(contractTypeLeftToJson).data);
      // jsondiffpatch.formatters.html.hideUnchanged();
      console.log('diffFormatted', diffFormatted);
    }
    return diffFormatted;
  }

  private setDiffInContainer(container: ElementRef, diff: string): void {
    container.nativeElement.innerHTML = diff;
  }

  private sortProperties(obj: any) {
    return Object.keys(obj)
      .sort()
      .reduce((r, k) => ((r[k] = obj[k]), r), {});
  }
}
