import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, ThemePalette } from '@angular/material';
import { Observable, Observer } from 'rxjs';
import { ConstantsService } from '../../shared/services/constants/constants.service';

@Component({
  selector: 'app-arsenal-confirm-dialog',
  templateUrl: './arsenal-confirm-dialog.component.html',
  styleUrls: ['./arsenal-confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArsenalConfirmDialogComponent {
  confirmButtonText: string;
  rejectButtonText: string;
  rejectButtonColor: ThemePalette;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ArsenalConfirmDialogComponent>) {
    this.confirmButtonText = data.confirmButtonText || 'Confirm';
    this.rejectButtonText = 'Cancel';
    this.rejectButtonColor = 'warn';
  }

  confirmAction(): void {
    this.dialogRef.close(true);
  }

  cancelAction(): void {
    this.dialogRef.close(false);
  }
}
