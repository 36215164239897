import { Injectable } from '@angular/core';
import { ApiMetadataApiService } from '@xpo-ltl/sdk-apimetadata';
import { User } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Provides access to the currently logged-in User
 */
@Injectable({
  providedIn: 'root',
})
export class XpoLtlLoggedInUserService {
  private isLoggedIn: boolean;

  /**
   * emits when the user is logged in
   */
  user$ = new BehaviorSubject<User>(null);

  constructor(private apiMetadataApiService: ApiMetadataApiService) {}

  get loggedInUser$(): Observable<User> {
    return this.user$.asObservable();
  }

  /**
   * Get the logged in user detail
   */
  initLoggedInUser(): void {
    if (this.isLoggedIn) {
      return;
    }

    this.apiMetadataApiService.loggedInUser().subscribe((user) => {
      this.user$.next(user);
      this.isLoggedIn = true;
    });
  }
}
