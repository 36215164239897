import { AfterViewInit, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'grid-severity',
  templateUrl: './grid-severity.component.html',
  styleUrls: ['./grid-severity.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridSeverityComponent implements AfterViewInit {
  severityOptions: string[];
  params: any;
  myControl = new FormControl();

  @ViewChild('severityList', { static: false }) selectRef: MatSelect;

  agInit(params): void {
    // debugger;
    this.params = params;
    this.severityOptions = params.severityOptions;
    this.myControl.setValue(params.value);
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      this.selectRef.focus();
      this.selectRef.open();
    });
  }

  onFocusOut(e) {
    if (!e.relatedTarget) {
      this.params.api.stopEditing();
    }
  }

  onSeveritySelected(e) {
    this.params.api.stopEditing();
  }

  getValue(): any {
    return this.myControl.value;
  }
}
