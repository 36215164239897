import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'xpo-grid-column-checkbox-renderer',
  template: `
    <xpo-icon *ngIf="isSelected" iconName="checkmark" xpoIconLarge></xpo-icon>
  `,
  styleUrls: ['./grid-column-check-icon-renderer.component.scss'],
})
export class GridColumnCheckIconRendererComponent implements ICellRendererAngularComp {
  isSelected: boolean;
  params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.isSelected = params['isSelected'];
  }

  refresh(params: ICellRendererParams) {
    return false;
  }
}
