import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, ThemePalette } from '@angular/material';
// tslint:disable-next-line:max-line-length
import { SelectedAllAttributesDialogComponent } from '../../arsenal-components/modules/features/components/selected-all-attributes-dialog/selected-all-attributes-dialog.component';

@Component({
  selector: 'attributes-list-dialog',
  templateUrl: './attributes-list-dialog.component.html',
  styleUrls: ['./attributes-list-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AttributesListDialogComponent implements OnInit {
  confirmButtonText: string;
  rejectButtonText: string;
  confirmButtonColor: ThemePalette;
  attributesFormGroup: FormGroup;
  attributes: any;
  selected: any;
  index: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AttributesListDialogComponent>,
    private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    this.confirmButtonText = data.confirmButtonText || 'Confirm';
    this.rejectButtonText = 'Cancel';
    this.attributes = data.attributes;
    this.index = data.index;
  }

  ngOnInit() {
    this.attributesFormGroup = this.formBuilder.group({
      attributes: this.formBuilder.array([]),
    });
  }

  confirmAction(): void {
    if (this.attributesFormGroup.value.attributes.length === this.attributes.length) {
      this.dialogRef.close([]);
      return;
    }
    this.dialogRef.close(this.attributesFormGroup.value.attributes);
  }

  cancelAction(): void {
    this.dialogRef.close(false);
  }

  onChange(event) {
    const attributes = (<FormArray>this.attributesFormGroup.get('attributes')) as FormArray;

    if (!event.checked) {
      const i = attributes.controls.findIndex((x) => x.value === event.source.value);
      attributes.removeAt(i);
      return;
    }

    attributes.push(new FormControl(event.source.value));

    if (attributes.length === this.attributes.length) {
      this.openSelectedAllDialog();
    }
  }

  openSelectedAllDialog() {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
    };
    this.dialog.open(SelectedAllAttributesDialogComponent, dialogConfig);
  }
}
