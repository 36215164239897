export enum SnackbarStatus {
  error = 'error',
  success = 'success',
  warn = 'warn',
  warning = 'warn',
}

export enum SnackbarNotifications {
  ADD_COMPONENT_SUCCESS = 'Exception Component has been successfully added!',
  ADD_COMPONENT_FAILED = 'Exception Component could not be added.',
  ADD_EXCEPTIONMSG_SUCCESS = 'Exception Message has been successfully added!',
  ADD_EXCEPTIONMSG_FAILED = 'Exception Message could not be added.',
  UPDATE_COMPONENT_SUCCESS = 'Exception Component has been successfully updated!',
  UPDATE_COMPONENT_FAILED = 'Exception Component could not be updated.',
  UPDATE_EXCEPTIONMSG_SUCCESS = 'Exception Message has been successfully updated!',
  UPDATE_EXCEPTIONMSG_FAILED = 'Exception Message could not be updated!',
}
