import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, ThemePalette } from '@angular/material';
import { XpoConfirmDialog, XpoConfirmDialogConfig } from '@xpo-ltl/ngx-ltl-core';
import { SelectedAllAttributesDialogComponent } from '../../../../selected-all-attributes-dialog/selected-all-attributes-dialog.component';

@Component({
  selector: 'contract-attributes-dialog',
  templateUrl: './contract-attributes-dialog.component.html',
  styleUrls: ['./contract-attributes-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContractAttributesDialogComponent implements OnInit {
  confirmButtonText: string;
  rejectButtonText: string;
  confirmButtonColor: ThemePalette;
  attributesFormGroup: FormGroup;
  attributes: any;
  type: string;
  selectedAttributes = [];

  index: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ContractAttributesDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private confirmDialog: XpoConfirmDialog
  ) {
    this.confirmButtonText = data.confirmButtonText || 'Confirm';
    this.rejectButtonText = 'Cancel';
    this.attributes = data.attributes;
    this.index = data.index;
  }

  ngOnInit(): void {
    const formControls = this.attributes.map(() => new FormControl(false));

    this.attributesFormGroup = this.formBuilder.group({
      attributes: new FormArray(formControls),
    });
  }

  confirmAction(): void {
    let attributes = this.selectedAttributes;
    if (this.selectedAttributes.length === this.attributes.length) {
      attributes = [];
    }
    this.dialogRef.close({ attributes });
  }

  cancelAction(): void {
    this.dialogRef.close(false);
  }

  onChange(event, attribute): void {
    if (!event.checked) {
      const indexToRemove = this.selectedAttributes.findIndex((attr) => attr.attributeName === attribute.attributeName);
      this.selectedAttributes.splice(indexToRemove, 1);
      return;
    }

    this.selectedAttributes.push(attribute);

    if (this.selectedAttributes.length === this.attributes.length) {
      this.openSelectedAllDialog();
    }
  }

  private openSelectedAllDialog() {
    const dialogConfig: MatDialogConfig = {
      disableClose: true,
    };
    this.dialog.open(SelectedAllAttributesDialogComponent, dialogConfig);
  }

  getControls() {
    return (this.attributesFormGroup.get('attributes') as FormArray).controls;
  }
}
