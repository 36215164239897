import { Injectable } from '@angular/core';
import { Component as ArsenalComponent, Contract, Feature, Type } from '@xpo-ltl/sdk-apimetadata';
import { ApiTypeCategoryCd } from '@xpo-ltl/sdk-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Actions } from '../../../enums/actions';
import { CachedType } from './../../../arsenal-components/models/cachedType';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private currentComponent = new BehaviorSubject<ArsenalComponent>(null);
  private currentComponentCd = new BehaviorSubject<string>(null);
  private currentMessageCd = new BehaviorSubject<string>(null);
  private currentFeature = new BehaviorSubject<Feature>(null);
  private currentFeatureName: string;
  private cachedTypes: CachedType[] = [];

  getCurrentComponent(): Observable<ArsenalComponent> {
    return this.currentComponent.asObservable();
  }

  setCurrentComponent(component: ArsenalComponent): void {
    this.currentComponent.next(component);
  }

  getCurrentComponentCd(): Observable<string> {
    return this.currentComponentCd.asObservable();
  }

  setCurrentComponentCd(componentCd: string): void {
    this.currentComponentCd.next(componentCd);
  }

  getCurrentMessageCd(): Observable<string> {
    return this.currentMessageCd.asObservable();
  }

  setCurrentMessageCd(componentCd: string): void {
    this.currentMessageCd.next(componentCd);
  }

  resetCurrentComponent(): void {
    this.currentComponent.next(null);
  }

  getCurrentFeature(): Observable<Feature> {
    return this.currentFeature.asObservable();
  }

  getCurrentFeatureNameOrAction(): Observable<string> {
    return this.currentFeature.asObservable().pipe(map((feature) => (feature ? feature.name : Actions.NEW_FEATURE)));
  }

  setCurrentFeature(feature: Feature): void {
    this.currentFeature.next(feature);
  }

  resetCurrentFeature(): void {
    this.currentFeature.next(null);
  }

  addContractToList(contract: Contract): void {
    const feature = this.currentFeature.value;
    feature.contract.push(contract);
    this.setCurrentFeature(feature);
  }

  addTypeToList(type: Type): void {
    const feature = this.currentFeature.value;
    feature.type.push(type);
    this.setCurrentFeature(feature);
  }

  // TODO: Review and refactor all the store updates, it's not working correctly
  deleteContractFromList(serviceName: string): void {
    const feature = this.currentFeature.value;
    const index = feature.contract.findIndex((c) => c.serviceName === serviceName);
    if (index) {
      feature.contract.splice(index, 1);
      this.setCurrentFeature(feature);
    }
  }

  deleteTypeFromList(typeName: string): void {
    const feature = this.currentFeature.value;
    const index = feature.type.findIndex((t) => t.typeName === typeName);
    if (index) {
      feature.contract.splice(index, 1);
      this.setCurrentFeature(feature);
    }
  }

  updateContract(contract: Contract): void {
    const feature = this.currentFeature.value;
    const index = feature.contract.findIndex((c) => c.serviceName === contract.serviceName);
    if (index) {
      feature.contract[index] = contract;
      this.setCurrentFeature(feature);
    }
  }

  updateType(type: Type): void {
    const feature = this.currentFeature.value;
    const index = feature.type.findIndex((t) => t.typeName === type.typeName);
    if (index) {
      feature.type[index] = type;
      this.setCurrentFeature(feature);
    }
  }

  // These are just for the breadcrumbs
  getCurrentFeatureName(): string {
    return this.currentFeatureName;
  }

  setCurrentFeatureName(featureName: string): void {
    this.currentFeatureName = featureName;
  }

  getCachedTypes(componentCd: string, categoryCd: string): any[] {
    const cachedTypeModel = this.cachedTypes.find((t) => t.componentCd === componentCd && t.categoryCd === categoryCd);
    if (!cachedTypeModel) {
      return null;
    }
    return cachedTypeModel.typesList;
  }

  setCachedTypes(componentCd: string, categoryCd: string, list: any[], update: boolean): void {
    if (!update) {
      this.cachedTypes.push(new CachedType(componentCd, list, categoryCd));
      return;
    }
    const indexToRemove = this.cachedTypes.findIndex((t) => t.componentCd === componentCd && t.categoryCd === categoryCd);
    this.cachedTypes.splice(indexToRemove, 1);
  }

  updateCachedType(categoryCd: string, componentCd: string, item: any): void {
    const cachedTypeToUpdate = this.cachedTypes.find((t) => t.componentCd === componentCd && t.categoryCd === categoryCd);

    if (cachedTypeToUpdate) {
      cachedTypeToUpdate.typesList.push(item);
    }
  }

  getCachedTypesByName(componentCd: string, categoryCd: string, name: string): any[] {
    const cachedTypeModel = this.cachedTypes.find((t) => {
      return t.componentCd === componentCd && t.categoryCd === categoryCd;
    });
    if (!cachedTypeModel) {
      return null;
    }
    return cachedTypeModel.typesList.find((type) => {
      return categoryCd === ApiTypeCategoryCd.CUSTOM ? type['typeName'] === name : type['serviceName'] === name;
    });
  }
}
