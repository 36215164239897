import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Type } from '@xpo-ltl/sdk-apimetadata/lib/api-apimetadata';
import { ApiTypeCategoryCd } from '@xpo-ltl/sdk-common';
import { StateService } from '../../../../../../../../shared/services/state/state.service';
import { TypesService } from '../../../../../../../services/types.service';
import { ContractAttributesDialogComponent } from '../../dialogs/contract-attributes-dialog/contract-attributes-dialog.component';

@Component({
  selector: 'grid-contract-attributes',
  templateUrl: './grid-contract-attributes.component.html',
  styleUrls: ['./grid-contract-attributes.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridContractAttributesComponent {
  params;
  attributesList;
  customAction;
  getAttributesList;
  featureId: number;
  isPrimitiveOrEnum: boolean;
  showButton: boolean = false;
  index: number;
  type: string;
  typeData: Type;
  selectedType: Type;
  cellLinkText: string = 'Select attributes';
  componentCd: string;

  constructor(public dialog: MatDialog, private typesService: TypesService, private stateService: StateService) {
    this.stateService.getCurrentComponentCd().subscribe((componentCd) => {
      this.componentCd = componentCd;
    });
  }

  agInit(params) {
    this.params = params;
    this.customAction = params.customAction;
    this.type = params.data.type;
    this.getAttributesList = params.getAttributesList;
    this.isPrimitiveOrEnum =
      params.data.category === ApiTypeCategoryCd.PRIMITIVE || params.data.category === ApiTypeCategoryCd.ENUM ? true : false;
    this.index = params.rowIndex;
  }

  openDialog() {
    this.featureId = this.getFeatureFromType(this.params.data.type);

    this.typesService.getTypeDetails(this.featureId, this.params.data.type).subscribe((resp) => {
      const dialogRef = this.dialog.open(ContractAttributesDialogComponent, {
        width: '400px',
        data: {
          title: `Select attributes`,
          message: ``,
          confirmButtonText: 'Done',
          attributes: resp.typeAttribute,
          index: this.index,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          result['type'] = this.typeData;
          this.customAction(this.params.rowIndex, result);
        }
      });
    });
  }

  getFeatureFromType(selectedType: string) {
    this.typeData = this.stateService
      .getCachedTypes(this.componentCd, this.params.data.category)
      .find((type) => type.typeName === selectedType);
    return this.typeData.featureId;
  }
}
