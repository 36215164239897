import { ChangeDetectionStrategy, Component, ElementRef, ViewChildren, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'grid-ocurrences',
  templateUrl: './grid-ocurrences.component.html',
  styleUrls: ['./grid-ocurrences.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'lau-GridCellOccurrences',
  },
})
export class GridOcurrencesComponent {
  params;
  min = new FormControl();
  max = new FormControl();

  @ViewChildren('minInput') minInput;
  @ViewChildren('maxInput') maxInput;

  constructor(public dialog: MatDialog) {}

  agInit(params) {
    this.params = params;
    this.min.setValue(params.value.min);
    this.max.setValue(params.value.max);
  }

  afterGuiAttached(params) {
    window.setTimeout(() => {
      this.minInput.first.nativeElement.focus();
    });
  }

  getValue(): any {
    return { min: this.min.value, max: this.max.value };
  }

  onFocusOut(e) {
    if (e.sourceCapabilities && !e.relatedTarget) {
      this.params.api.stopEditing();
    }
  }

  onMinTabEvent(e): void {
    e.stopPropagation();
    e.preventDefault();
    this.maxInput.first.nativeElement.focus();
  }

  onMaxTabEvent(e): void {
    e.stopPropagation();
    e.preventDefault();

    this.params.api.stopEditing();
  }
}
